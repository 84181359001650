<template>
    <b-dropdown
        ref="dropdown"
        append-to-body
        aria-role="menu"
        position="is-bottom-left"
        trap-focus
        @active-change="onDropdownToggle">
        <template #trigger>
            <LTooltip
                :type="disabled ? 'is-danger' : tooltipType"
                :label="tooltipLabel">
                <div
                    class="is-align-items-center"
                    :class="statusColorIcon">
                    <InlineSvg
                        :style="[{ cursor: disabled ? 'not-allowed' : 'pointer' }, stylesIcon]"
                        :src="iconRequest"
                        alt="request">
                    </InlineSvg>
                </div>
            </LTooltip>
        </template>

        <form
            v-if="isActive"
            class="comment-form px-4 py-2"
            @submit.prevent="submitFormMethod">
            <h6 class="title is-6 mb-0">
                {{ $t(`admin.offers.capacity.table.dropdown.titlePayout`) }}
            </h6>

            <div
                v-if="(offer && webmaster) && status === 'PENDING' || !status"
                class="has-text-grey mt-1">
                {{ $t('admin.offers.capacity.table.dropdown.offer') }}
                <span class="has-text-black">
                    {{ `${ offer.detail.name } [${ offer.intId }]` }}
                </span> <br>
                {{ $t('admin.offers.capacity.table.dropdown.webmaster') }}
                <span class="has-text-black">
                    {{ webmaster.intId }}
                </span>
                <template v-if="externalWebmasterId">
                    <br>
                    {{ $t('admin.offers.capacity.table.dropdown.externalWebmaster') }}
                    <span class="has-text-black">
                        {{ externalWebmasterId }}
                    </span>
                </template>
                <template v-if="subAccounts && subAccounts.length">
                    <br>
                    <div
                        class="flex is-align-items-center"
                        style="flex-wrap: wrap; gap: 6px;">
                        {{ $t('admin.offers.capacity.table.dropdown.subAccounts') }}:
                        <b-tag
                            :key="idx"
                            v-for="(item, idx) in subAccounts"
                            type="is-info is-light">
                            {{ item.intId }}
                        </b-tag>
                    </div>
                </template>
            </div>

            <span
                v-if="status"
                :class="[statusColor, 'subtitle is-7']">
                {{ $t(`admin.offers.capacity.table.dropdown.status.${ status }`) }}
            </span>

            <span
                v-if="createdAt || updatedAt"
                :class="[statusColor, 'subtitle is-7']">
                {{ createOrUpdateDate }}
            </span>

            <div class="is-flex is-gapless mt-3">
                <b-field
                    :label="$t(`admin.offers.capacity.table.dropdown.newPayout`)"
                    class="nowrap-help">
                    <LInput
                        v-model.number="newPayoutValue"
                        :disabled="hasApproveAndPending || status === statusEnum.APPROVED || status === statusEnum.REJECTED || isLoading.minimalPayout"
                        :loading="!hasApproveAndPending && isLoading.minimalPayout"
                        type="number"
                        :min="realMinimalPayout"
                        step="0.01"
                        icon-right=""
                        required>
                    </LInput>
                </b-field>

                <b-field :label="$t(`admin.offers.capacity.table.dropdown.oldPayout`)">
                    <LInput
                        :value="oldValueOrPayout"
                        disabled>
                    </LInput>
                </b-field>
            </div>

            <Permissions :permissions="['STATISTICS.SHOW_COMMISSION']">
                <div
                    class="is-size-7 mt-1">
                    {{ $t(`admin.offer.form.advertisers.labels.minimalReward`) }}:
                    <InlineLoader
                        v-if="isLoading.minimalPayout"
                        class="p-0 ml-2"
                        style="height: 12px"
                        active>
                    </InlineLoader>
                    <span v-else>
                        {{ formatCurrency(realMinimalPayout, minimalPayoutCurrency) }}
                    </span>
                </div>
            </Permissions>

            <Permissions :permissions="['STATISTICS.SHOW_COMMISSION']">
                <div
                    v-if="status && data.webmasterRate"
                    class="mb-3 is-size-7">
                    {{ $t("admin.offers.capacity.table.dropdown.webmasterRate") }}
                    <span class="ml-1">
                        {{ rate }}
                    </span>
                </div>
            </Permissions>

            <div class="my-3">
                <b-field
                    v-if="!isApproveRequest || !status"
                    class="mb-3"
                    :label="$t('admin.offers.capacity.table.dropdown.webmasterRate')"
                    :message="rateMessage">
                    <LInput
                        v-model.number="webmasterRate"
                        expanded
                        :disabled="newPayoutValue < minimalPayoutValue"
                        type="number"
                        :min="0"
                        :max="maxIndividualRate"
                        step="0.01"
                        required>
                    </LInput>
                </b-field>

                <Permissions :permissions="['STATISTICS.SHOW_COMMISSION']">
                    <div
                        v-if="currentRate"
                        class="mb-3 is-size-7">
                        {{ $t("admin.offers.capacity.table.dropdown.currentRate") }}
                        <span class="ml-1">
                            {{ fixedCurrency(currentRate) }}
                        </span>
                    </div>
                </Permissions>

                <b-field :label="$t(`admin.offers.capacity.table.dropdown.goal`) + ' :'">
                    <Select
                        v-model="goal"
                        class="white"
                        :get-data="getGoals"
                        :placeholder="$t(`admin.offers.capacity.table.dropdown.goal`)"
                        :disabled="hasApproveAndPending || status === statusEnum.APPROVED || status === statusEnum.REJECTED"
                        required
                        field="label"
                        prop="value"
                        @input="setGoal">
                    </Select>
                </b-field>

                <b-field :label="$t(`admin.offers.capacity.table.dropdown.reason`)">
                    <Select
                        v-model="reason"
                        class="white"
                        :disabled="!goal || hasApproveAndPending || status === statusEnum.APPROVED || status === statusEnum.REJECTED"
                        :seed="goal"
                        :get-data="getReasons"
                        :placeholder="$t(`admin.offers.capacity.table.dropdown.reason`)"
                        required
                        field="label"
                        prop="value">
                    </Select>
                </b-field>
            </div>

            <b-field
                v-if="executorComment && status === 'PENDING' && !isApproveRequest"
                :label="$t(`admin.offers.capacity.table.dropdown.commentManager`)"
                class="comment-creator">
                <span>{{ executorComment }}</span>
            </b-field>

            <b-field
                v-if="!isApproveRequest || isApproveRequest && !status"
                :label="$t(`admin.offers.capacity.table.dropdown.comment`)">
                <LInput
                    v-model="creatorComment"
                    :placeholder="$t(`admin.offers.capacity.table.dropdown.enterText`)"
                    maxlength="1000"
                    minlength="0"
                    :disabled="status === statusEnum.APPROVED || status === statusEnum.REJECTED"
                    :has-counter="false"
                    type="textarea">
                </LInput>
            </b-field>

            <template v-else>
                <b-field
                    :label="$t(`admin.offers.capacity.table.dropdown.commentManager`)"
                    class="comment-creator">
                    <span>
                        <ShowMore
                            :max-string="150"
                            multiline
                            :data="creatorComment">
                        </ShowMore>
                    </span>
                </b-field>

                <b-field v-if="status === statusEnum.PENDING">
                    <template #label>
                        <div class="is-flex">
                            {{ $t(`admin.offers.capacity.table.dropdown.myComment`) }}
                            <LabelWithAnnotation :tooltip="$t(`admin.offers.capacity.table.dropdown.annotations.myComment`)">
                            </LabelWithAnnotation>
                        </div>
                    </template>
                    <LInput
                        v-model="executorComment"
                        :placeholder="$t(`admin.offers.capacity.table.dropdown.enterText`)"
                        maxlength="1000"
                        minlength="0"
                        :has-counter="false"
                        type="textarea">
                    </LInput>
                </b-field>

                <b-field
                    v-else
                    :label="$t(`admin.offers.capacity.table.dropdown.myComment`)"
                    class="comment-creator">
                    <span>
                        <ShowMore
                            :max-string="150"
                            multiline
                            :data="executorComment">
                        </ShowMore>
                    </span>
                </b-field>
            </template>

            <template v-if="isEditRequest && status !== statusEnum.APPROVED && status !== statusEnum.REJECTED">
                <b-button
                    type="is-info"
                    class="mr-3"
                    native-type="submit">
                    {{ submitButtonText }}
                </b-button>

                <b-button
                    v-if="data && status === statusEnum.PENDING"
                    type="is-danger is-light"
                    @click="capacityRequestMethod(actionsEnum.DELETE)">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.deleteRequest`) }}
                </b-button>
            </template>

            <template
                v-else-if="hasApproveAndPending">
                <b-button
                    type="is-info"
                    class="mr-3"
                    @click="capacityRequestMethod(actionsEnum.APPROVE)">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.approve`) }}
                </b-button>

                <b-button
                    type="is-danger is-light"
                    :disabled="!executorComment"
                    @click="capacityRequestMethod(actionsEnum.REJECT)">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.reject`) }}
                </b-button>
            </template>

            <template v-else>
                <b-button
                    class="mr-3"
                    @click="closeDropdown">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.close`) }}
                </b-button>
                <b-button
                    class="mr-3"
                    type="is-info is-light"
                    @click="resendRequest">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.newRequest`) }}
                </b-button>
            </template>
        </form>
    </b-dropdown>
</template>

<script>
  import ShowMore from "@/components/Common/ShowMore";
  import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
  import InlineSvg from "vue-inline-svg";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { SET_PAYOUT_REQUEST, SET_EMPTY } from "@core/store/mutation-constants";
  import {
    GET_ALLOWED_OFFER_PARAMETERS,
    ACTUALIZE_PAYOUT_REQUEST,
    APPROVE_PAYOUT_REQUEST,
    CREATE_PAYOUT_REQUEST,
    DELETE_PAYOUT_REQUEST,
    EDIT_PAYOUT_REQUEST,
    REJECT_PAYOUT_REQUEST,
    UPDATE_PAYOUT_REQUEST
  } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { hasPermissions } from "@core/mixins/permissions";
  import { formatCurrency } from "@core/filters";
  import Select from "@/components/Common/Select/Select.vue";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { statusEnum, actionsEnum, goalEnum, reasonsEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";
  import { fixedCurrency } from "@core/flowMethods";
  import { DropdownCapacity } from "@core/mixins";
  import Permissions from "@/components/Common/Permissions.vue";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "DropdownCapacityPayout",
    mixins: [DropdownCapacity],
    components: { Permissions, LabelWithAnnotation, InlineSvg, Select, ShowMore, InlineLoader },

    props: {
      oldPayout: {
        type: String,
        default: null
      },

      currentRate: {
        type: Object,
        default: null
      },

      externalWebmasterId: {
        type: String,
        default: null
      },

      disabled: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        localExecutorComment: null
      };
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      ...mapState({
        isApproveRequest: ({ auth }) => hasPermissions(["PAYOUT.REQUEST.APPROVE"], auth.userPermissions)
      }),

      ...mapState("admin/capacityAndBumps", {
        allowedOfferParameters: ({ allowedOfferParameters }) => allowedOfferParameters.data ?? {}
      }),

      ...mapFields("admin/capacityAndBumps/capacity/webmastersCapacity/payoutRequest", {
        fields: [
          "newPayoutValue",
          "oldPayoutValue",
          "creatorComment",
          "executorComment",
          "status",
          "createdAt",
          "updatedAt",
          "webmasterRate",
          "goal",
          "id",
          "reason"
        ],
        base: "data",
        action: UPDATE_PAYOUT_REQUEST
      }),

      subAccounts () {
        // const currentWebmaster = this.data.webmaster.webmasters.find(el => el.id === this.webmasterId);
        return this.data?.webmaster?.webmasters;
      },

      tooltipLabel () {
        return this.$t(`admin.offers.capacity.table.${ this.disabled ? "messages.withoutCapacity" : "dropdown.titlePayout" }`);
      },

      oldValueOrPayout () {
        return this.oldPayoutValue ?? this.oldPayout;
      },

      rate () {
        return formatCurrency(this.data.webmasterRate, this.minimalPayoutCurrency);
      },

      minimalPayoutValue () {
        return this.allowedOfferParameters.minimalPayout?.value;
      },

      minimalPayoutCurrency () {
        return this.allowedOfferParameters.minimalPayout?.currency;
      },

      minimalCommission () {
        return this.allowedOfferParameters.minimalCommission;
      },

      realMinimalPayout () {
        const { minimalCommission } = this;
        if (this.minimalPayoutValue && minimalCommission) {
          return this.minimalPayoutValue + minimalCommission.value;
        } return 0;
      },

      isLoading () {
        return {
          minimalPayout: this.$wait(`admin/capacityAndBumps/allowedOfferParameters/${ GET_ALLOWED_OFFER_PARAMETERS }`)
        };
      },

      maxIndividualRate () {
        return this.newPayoutValue - this.minimalCommission?.value;
      },

      rateMessage () {
        if (this.isShowCommissions) {
          const { maxIndividualRate, newPayoutValue } = this;
          return newPayoutValue >= this.minimalPayoutValue
            ? this.$t("admin.offers.capacity.table.messages.maxIndividualRate",
                      { rate: formatCurrency(maxIndividualRate, this.minimalPayoutCurrency) })
            : null;
        } else {
          return "";
        }
      },

      hasApproveAndPending () {
        return this.status === statusEnum.PENDING && this.isApproveRequest;
      },

      isApprovedOrRejectedRequest () {
        return this.$wait(`admin/capacityAndBumps/capacity/webmastersCapacity/payoutRequest/${ APPROVE_PAYOUT_REQUEST }`) ||
          this.$wait(`admin/capacityAndBumps/capacity/webmastersCapacity/payoutRequest/${ REJECT_PAYOUT_REQUEST }`);
      }
    },

    methods: {
      ...mapActions("admin/capacityAndBumps/capacity/webmastersCapacity/payoutRequest", {
        CREATE_PAYOUT_REQUEST,
        EDIT_PAYOUT_REQUEST,
        DELETE_PAYOUT_REQUEST,
        APPROVE_PAYOUT_REQUEST,
        REJECT_PAYOUT_REQUEST,
        ACTUALIZE_PAYOUT_REQUEST
      }),

      ...mapActions("admin/capacityAndBumps", {
        GET_ALLOWED_OFFER_PARAMETERS: `allowedOfferParameters/${ GET_ALLOWED_OFFER_PARAMETERS }`
      }),

      ...mapMutations("admin/capacityAndBumps/capacity/webmastersCapacity/payoutRequest", {
        SET_PAYOUT_REQUEST,
        SET_EMPTY
      }),

      onDropdownToggle (isActive) {
        if (!this.disabled) {
          const { data, id } = this;
          this.isActive = isActive;

          if (data && isActive) {
            this.SET_PAYOUT_REQUEST(data);
            this.localExecutorComment = this.executorComment;
          } else {
            if (!this.isApprovedOrRejectedRequest && data?.id === id && this.localExecutorComment !== this.executorComment) {
              this.closeDropdown();
              this.capacityRequestMethod(actionsEnum.ACTUALIZE);
              this.localExecutorComment = null;
            }
            if (!isActive) return;
            this.SET_EMPTY();
          }
        }
      },

      async capacityRequestMethod (action) {
        const { offerId, webmasterId, externalWebmasterId, moduleNamespace } = this;
        const method = action + "_PAYOUT_REQUEST";
        const namespace = `admin.offers.capacity.table.dropdown.message.${ action }`;

        if (action === actionsEnum.APPROVE) {
          this.$emit("update:localLoading", true);
        }

        this.closeDropdown();
        await actionWithToast(this[method]({ offerId, webmasterId, externalWebmasterId, moduleNamespace }), namespace);

        this.$emit("submit");
        this.$emit("update:localLoading", false);
      },

      getGoals () {
        const items = [
          {
            value: goalEnum.SCALING,
            label: this.$t("admin.offers.capacity.table.dropdown.values.scaling")
          },
          {
            value: goalEnum.HOLD,
            label: this.$t("admin.offers.capacity.table.dropdown.values.hold")
          }
        ];
        return {
          items,
          count: items.length
        };
      },

      setGoal () {
        this.reason = null;
      },

      getReasons () {
        if (this.goal) {
          const items = Object.values(reasonsEnum[this.goal]).map(reason => {
            return {
              value: reason,
              label: this.$t(`admin.offers.capacity.table.dropdown.reasons.${ reason }`)
            };
          });

          return {
            items,
            count: items.length
          };
        }
      },

      formatCurrency,
      fixedCurrency
    },

    watch: {
      isActive (value) {
        if (value) {
          const { webmasterId, offerId } = this;
          this.isShowCommissions && this.GET_ALLOWED_OFFER_PARAMETERS({ webmasterId, offerId });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "~@/scss/_dropdown-capacity.scss";

    .nowrap-help {
        ::v-deep {
            margin-bottom: 0;

            .help {
                white-space: nowrap;
                width: 164px;
                position: relative;
            }
        }
    }

    ::v-deep {
        .input {
            padding-right: calc(0.75em - 1px) !important;
        }

        .icon.is-right {
            display: none;
        }

        .is-expanded {
            width: 100%
        }
    }
</style>